import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { Description } from 'components/Global/Caption/styled'

const FloorMapStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex: 1;
  overflow-y: hidden;
  min-width: fit-content;

  .PresenceWrapper.file {
    display: none;
  }

  .Image__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    ${({ image }) => !image && 'width: 100%;'}
    .ant-image {
      z-index: -1;
    }
  }
`

export const ImageContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:focus {
    outline: none;
  }

  > img,
  > svg {
    height: 100%;
    width: auto;
    object-fit: scale-down;
  }

  > .Icon {
    position: absolute;
    width: 80%;
    height: 80%;
    color: ${({ theme }) => theme.color.softStroke};
    z-index: -1;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  > .Button {
    z-index: 100;
    transition: background-color 0.2s ease;
    position: absolute;
    top: 1rem;
    border: 1px solid ${({ theme }) => theme.color.softText};
    border-radius: 1rem;
    padding: 0 1rem;
    background-color: ${({ theme }) => transparentize(0.2, theme.color.backgroundFront)};

    &:hover {
      background-color: ${({ theme }) => theme.color.backgroundFront};
    }

    .Icon {
      margin-right: 0.5rem;
    }
  }

  .PresenceWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: ${({ theme }) => transparentize(0.2, theme.color.backgroundFront)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.softText};
    border-radius: 1rem;
    margin: 1rem;

    &.drop {
      border: 3px dashed ${({ theme }) => theme.color.softText};
    }

    &.uploading {
      bottom: auto;
      right: auto;
      box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
      background-color: ${({ theme }) => transparentize(0.2, theme.color.backgroundFront)};

      .Caption {
        flex-direction: row;
        padding: 1rem;

        .Icon {
          width: 1rem;
          height: 1rem;
          color: ${({ theme }) => theme.color.softText};
          display: flex;
        }

        .Title {
          color: ${({ theme }) => theme.color.softText};
          font-size: 1rem;
          margin: 0 0.5rem;
        }
      }
    }

    .Caption {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .loader {
        position: relative;
        width: 8rem;
        height: 1rem;
        border: 2px solid ${({ theme }) => theme.color.softText};
        border-radius: 5rem;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          top: 2px;
          bottom: 2px;
          left: 2px;
          right: 2px;
          border-radius: 5rem;
          background: ${({ theme }) => theme.color.softText};
          animation: loader 1s ease infinite;
        }
      }
    }

    @keyframes loader {
      0% {
        left: 2px;
        right: calc(100% - 2px);
      }
      50% {
        left: 2px;
        right: 2px;
      }
      100% {
        left: calc(100% - 2px);
        right: 2px;
      }
    }

    .Icon {
      width: 5rem;
      height: 5rem;
      color: ${({ theme }) => theme.color.softText};
    }

    .Title {
      color: ${({ theme }) => theme.color.softText};
      margin: 1rem;
    }
  }
`

export const microWidgets = css`
  .Caption:not(.DataPointLabel) {
    .Title {
      font-size: 1rem !important;
      padding: 0.5rem;
    }

    .Icon {
      width: 2rem;
      padding: 0.2rem;
    }

    .kpi {
      line-height: 0.8;
      margin-bottom: 0;

      span {
        font-size: 1.5rem;
      }

      label {
        font-size: 9px;
      }
    }
  }

  .ChartCard {
    width: auto !important;
    height: auto !important;

    &__container {
      height: 2rem !important;
      min-height: 2rem !important;
      flex: none !important;
    }

    .Caption {
      > .Icon {
        width: 1.8rem !important;
        height: 1.8rem;
        font-size: 1rem;
        padding: 0 !important;
      }
    }
  }

  .LastValueWidget {
    padding: 0;
    width: auto;
    min-width: auto;
    max-width: 12rem;

    .LastValueWidget__title-container {
      padding: 0 !important;
      align-items: center;
    }

    .LastValueWidget__caption {
      padding: 0.3rem;
      flex-direction: row;

      .Icon {
        position: static !important;
        width: 1.8rem !important;
        min-width: 1.8rem !important;
        height: 1.8rem !important;
        font-size: 1rem;
        padding: 0.3rem;
      }
    }

    .LastValueWidget__kpi-container {
      padding: 0 !important;
    }

    &.s {
      .LastValueWidget__caption {
        .Icon {
          width: 1.8rem !important;
          min-width: 1.8rem !important;
          height: 1.8rem !important;
        }
        .kpi {
          span {
            font-size: 1rem;
          }
        }
      }
    }

    &.m {
      .LastValueWidget__caption {
        .Icon {
          width: 2.8rem !important;
          height: 2.8rem;
        }
        .kpi {
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

    &.l {
      .LastValueWidget__caption {
        .Icon {
          width: 4.8rem !important;
          height: 4.8rem;
        }
      }
    }

    .LastValueWidgetCaption {
      height: 100px;
    }
  }

  .LastValueWidgetContainer {
    min-width: 8rem;
    .kpi {
      margin-left: auto;
    }
  }

  .NavigationLabel {
    .Icon {
      img {
        height: 100%;
        width: auto;
      }
    }
    &.s {
      .Icon {
        height: 3rem;
      }
    }

    &.m {
      .Icon {
        height: 6rem;
      }
    }

    &.l {
      .Icon {
        height: 9rem;
      }
    }

    &.auto {
    }
  }

  .Gauge {
    width: 10rem !important;
    height: 10rem !important;
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    background: ${({ theme }) => theme.color.backgroundFront};

    .Caption {
      padding: 0;

      .Icon {
        margin: 0 0.2rem 0 0;
      }
    }

    .data-labels {
      bottom: -5px;

      .value {
        font-size: 1.2rem;
      }

      .unit {
        font-size: 9px !important;
      }
    }

    .highcharts-axis-labels text {
      font-size: 5px !important;
    }
  }

  .CircleGauge {
    width: 10rem !important;
    height: 10rem !important;
    box-shadow: ${({ theme }) => theme.shadowElevation2(theme.darkMode)};
    background: ${({ theme }) => theme.color.backgroundFront};
    border-radius: 0.5rem;
    overflow: hidden;

    .Caption {
      padding: 0;

      .Icon {
        margin: 0 0.2rem 0 0;
      }
    }

    .CircleGauge__caption {
      h5 {
        font-size: 9px !important;

        strong {
          font-size: 1.2rem;
        }
      }
    }

    .highcharts-axis-labels text {
      font-size: 5px !important;
    }
  }
`

export const PositionWrapperStyled = styled(motion.div)`
  position: absolute;
  text-align: center;
  min-width: fit-content;
  transition: box-shadow 0.2s ease, border 0.1s ease, opacity 0.2s ease;
  z-index: ${({ type }) => type !== 'Highlight' && 1};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadowElevation3(theme.darkMode)};
    opacity: 1 !important;
  }

  ${({ hoveredEdge, theme }) =>
    hoveredEdge &&
    (hoveredEdge.notHovered
      ? css`
          opacity: 0.8;
        `
      : css`
          box-shadow: ${theme.shadowElevation3(theme.darkMode)};
        `)}
  ${({ size, circle, type }) =>
    size &&
    type === 'Highlight' &&
    css`
      width: ${size.width};
      height: ${circle ? 0 : size.height};
      padding-bottom: ${circle ? size.width : 0};
      border-radius: ${circle && '100%'};
    `}
  ${({ isPreviewMode, type }) =>
    isPreviewMode && type !== 'Highlight'
      ? css`
          &:after {
            content: '';
            position: absolute;
            top: -5px;
            bottom: -5px;
            left: -5px;
            right: -5px;
            z-index: 1;
            cursor: grab;
            border-radius: 0.2rem;
          }
        `
      : css``}
  ${({ isSelected, theme, type }) =>
    isSelected &&
    type !== 'Highlight' &&
    css`
      &:after {
        border: 2px solid ${theme.color.main};
      }
    `}
  ${Description} {
    ${({ pos }) =>
      pos.y < 5 &&
      css`
        top: 100%;
        bottom: auto !important;
        margin-top: 0.3rem;
      `}
  }

  ${microWidgets}
`

export const LineStyled = styled.svg`
  z-index: 50;
  position: fixed;
  top: ${({ line }) => (line[0].top > line[1].top ? line[1].top : line[0].top)}px;
  left: ${({ line }) => (line[0].left > line[1].left ? line[1].left : line[0].left)}px;
  height: ${({ line }) => Math.abs(line[1].top - line[0].top)}px;
  width: ${({ line }) => Math.abs(line[1].left - line[0].left)}px;
`

export default FloorMapStyled
