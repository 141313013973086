import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { UiContext } from '../Layout/UiProvider'

export const useWindowSize = (breakpoints) => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => {
    const width = isClient ? window.innerWidth : breakpoints.l

    return {
      width,
      height: isClient ? window.innerHeight : undefined,
      screen: Object.entries(breakpoints || {}).reduce(
        (screen, [breakpoint, breakpointSize]) =>
          width > breakpointSize
            ? { current: breakpoint, all: screen.all.concat(breakpoint) }
            : { current: screen.current, all: screen.all.concat(breakpoint) },
        { current: 's', all: [] }
      ),
    }
  }, [breakpoints, isClient])

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  return windowSize
}

export const useOnClickOutside = (ref, handler, ignored) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (ignored && ignored.includes(event.target.dataset.cy))
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, ignored])
}

export const useSearchThrottle = ({ callback, reset, interval = 500, minChar = 2 }) => {
  const [shouldFire, setShouldFire] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const timeout = useRef()

  const handleOnSearch = (e) => {
    setSearchValue(e.target.value)

    if (shouldFire) {
      callback({ value: e.target.value })
      return setShouldFire(false)
    }

    timeout.current = setTimeout(() => {
      setShouldFire(true)
    }, interval)
  }

  useEffect(() => {
    if (shouldFire && (searchValue.length >= minChar || searchValue.length === 0)) {
      if (callback) {
        callback({ value: searchValue })
      }
    }
  }, [searchValue, callback, shouldFire, minChar, reset])

  useEffect(() => {
    if (reset) {
      setSearchValue('')
    }
  }, [reset])

  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  return [searchValue, handleOnSearch]
}

export const useContainerSize = (
  initialSize,
  h = null,
  w = null,
  screenSize = null,
  timeout = 1000,
  resizeTrigger
) => {
  const [containerSize, setContainerSize] = useState(initialSize || { width: null, height: null })
  const {
    state: {
      mainPanel: { resizeToggle },
    },
  } = useContext(UiContext)
  const [firstMount, setFirstMount] = useState(false)

  const container = useRef()
  useEffect(() => {
    const localTimeoutId = setTimeout(() => {
      setContainerSize({
        width: container.current?.clientWidth,
        height: container.current?.clientHeight,
      })
    }, timeout)
    return () => clearTimeout(localTimeoutId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current, h, w, screenSize, timeout])

  useEffect(() => {
    const localTimeoutId = setTimeout(() => {
      setContainerSize({
        width: container.current?.clientWidth,
        height: container.current?.clientHeight,
      })
      !firstMount && setFirstMount(true)
    }, timeout)
    return () => clearTimeout(localTimeoutId)
  }, [resizeToggle, firstMount, timeout, resizeTrigger])

  return [container, containerSize]
}

export const useForceUpdate = () => {
  const [, forceUpdate] = useState()

  return useCallback(() => {
    forceUpdate((s) => !s)
  }, [])
}

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useIntersection = (options, shouldAlwaysRender) => {
  const [observerEntry, setEntry] = useState({})
  const elRef = useRef()

  useEffect(() => {
    if (shouldAlwaysRender) {
      return setEntry(true)
    }
    const observer = new IntersectionObserver((entries) => setEntry(entries[0]), options)
    observer.observe(elRef.current)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elRef])
  return { observerEntry, elRef }
}

export const useRenderOnEnterViewport = (shouldAlwaysRender) => {
  const { observerEntry, elRef } = useIntersection(
    {
      root: null,
      threshold: 0,
      rootMargin: '0px 0px 0px 0px',
    },
    shouldAlwaysRender
  )

  const [renderContent, setRenderContent] = useState({
    render: shouldAlwaysRender,
    flag: false,
  })

  useEffect(() => {
    if (observerEntry && observerEntry.isIntersecting) {
      setRenderContent({ render: renderContent.flag, flag: true })
    } else if (!shouldAlwaysRender && !renderContent.flag) {
      setRenderContent({ render: renderContent.flag || renderContent.render, flag: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerEntry && observerEntry.isIntersecting])

  return [elRef, renderContent.render, observerEntry]
}

export const usePortal = (id) => {
  const rootElemRef = useRef(document.createElement('div'))

  useEffect(function setupElement() {
    const parentElem = document.querySelector(`#${id}`)
    const ref = rootElemRef.current
    parentElem.appendChild(ref)

    return function removeElement() {
      ref.remove()
    }
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return rootElemRef.current
}

export const useTimer = (timer = 1000, bypass) => {
  const [expired, setExpired] = useState(!!bypass)
  useEffect(() => {
    let timeout
    if (!bypass) {
      timeout = setTimeout(() => {
        setExpired(true)
      }, timer)
    }
    return () => timeout && clearTimeout(timeout)
    // TODO: check hook dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return expired
}

export const useScroll = (trackScroll) => {
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const startPosition = useRef(0)

  const onScroll = useCallback(() => {
    let currentPosition = window.pageYOffset // or use document.documentElement.scrollTop;
    if (currentPosition > scrollTop) {
      // downscroll code
    } else {
      // upscroll code
    }
    if (!scrolling) {
      startPosition.current = currentPosition
      setScrolling(true)
    }
    setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
  }, [scrollTop, scrolling])

  useEffect(() => {
    if (trackScroll) {
      onScroll()
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll, scrollTop, trackScroll])

  return { scrolling }
}
